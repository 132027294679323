import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col, Table, Button, Modal, Form, Input, notification } from 'antd'
import { Helmet } from 'react-helmet'
import 'bootstrap/scss/bootstrap.scss'
import client from '../../../api/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
const EmailTemplate = (props) => {
  const [editEmailFormRef] = Form.useForm()
  const [emailTemplate, setEmailTemplate] = useState([])
  const [loading, setLoading] = useState(false)
  const [editEmailModal, setEditEmailModal] = useState(false)
  const fetchEmailList = useCallback(() => {
    setLoading(true)
    client
      .get(`/api/admin/emailtemplates`)
      .then(async (res) => {
        if (res.status === 200) {
          setEmailTemplate(res.data)
        }
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    fetchEmailList()
  }, [fetchEmailList])
  // handleEditEmail
  const handleEditEmail = () => {
    editEmailFormRef.validateFields().then(async (val) => {
      client
        .put(`/api/admin/emailtemplates/edit?id=${val.id}`, { code: val.code, subject: val.subject, content: val.content })
        .then(async (res) => {
          if (res.status === 200) {
            notification.destroy()
            notification.success({
              message: 'И-мейлийн мэдээлэл амжилттай засагдлаа!',
            })
            setEditEmailModal(false)
            fetchEmailList()
            setLoading(false)
          }
        })
        .catch((err) => console.error({ err }))
    })
  }
  const columns = [
    {
      title: '#',
      className: 'text-center',
      width: '8%',
      dataIndex: 'id',
    },
    {
      title: 'Нэр',
      className: 'text-left',
      dataIndex: 'code',
      responsive: ['md'],
      render: (code) => {
        return (
          <div title={code} style={{ textOverflow: 'ellipsis' }}>
            {code?.length > 50 ? code.substr(0, 49) + '...' : code}
          </div>
        )
      },
    },
    {
      title: 'subject',
      className: 'text-left',
      responsive: ['md'],
      dataIndex: 'subject',
      render: (subject) => {
        return (
          <div title={subject} style={{ textOverflow: 'ellipsis' }}>
            {subject?.length > 50 ? subject.substr(0, 49) + '...' : subject}
          </div>
        )
      },
    },
    {
      title: 'Content',
      className: 'text-left',
      responsive: ['md'],
      dataIndex: 'content',
      render: (content) => {
        return (
          <div title={content} style={{ textOverflow: 'ellipsis' }}>
            {content?.length > 100 ? content.substr(0, 99) + '...' : content}
          </div>
        )
      },
    },
    {
      hidden: !props.isOwner,
      title: 'Үйлдэл',
      className: 'text-center',
      width: '80px',
      render: (record) => (
        <Row>
          <Button
            className="btn-sb btn-sb-primary w-100"
            onClick={() => {
              editEmailFormRef.setFieldsValue(record)
              setEditEmailModal(true)
            }}
          >
            <FontAwesomeIcon icon={faEdit} size="1x" className="mx-2" />
          </Button>
        </Row>
      ),
    },
  ].filter((c) => !c.hidden)

  return (
    <>
      <Helmet title="Мессежийн загвар" defer={false} />
      <Row gutter={[30, 0]}>
        <Col span={24}>
          <Row className="sb-header">
            <Col flex="auto">
              <span className="sb-text-medium">И-мейлийн загварууд </span>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Table
              className="sb-table w-100 mt-3"
              dataSource={emailTemplate}
              columns={columns}
              rowKey={(record) => record.id}
              locale={{ emptyText: 'Мэдээлэл байхгүй байна' }}
              pagination={false}
              loading={loading}
            />
          </Row>
        </Col>
      </Row>
      <Modal
        open={editEmailModal}
        onCancel={() => setEditEmailModal(false)}
        title="И-мейл мэдээлэл"
        className="sb-modal sb-modal-kiosk"
        width={700}
        destroyOnClose
        centered
        maskStyle={{
          background: '#1142FB9',
        }}
        footer={[
          <Row justify={'center'} key="modal" gutter={[12, 24]}>
            <Col span={12}>
              <Button className="btn-sb btn-sb-primary w-100" onClick={() => handleEditEmail()}>
                Хадгалах
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <Form name="edit_email" form={editEmailFormRef}>
          <Row gutter={[16, 0]}>
            <Col span={12} className="d-none">
              <Form.Item className="mb-3" name="id">
                <Input className="sb-input sb-shadow" placeholder="Id" disabled />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                className="mb-3"
                name="code"
                rules={[
                  {
                    required: true,
                    email: 'Нэрээ оруулна уу!',
                  },
                ]}
              >
                <Input className="sb-input sb-shadow" placeholder="Нэр" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                className="mb-3"
                name="subject"
                rules={[
                  {
                    required: true,
                    email: 'Subject оруулна уу!',
                  },
                ]}
              >
                <Input.TextArea rows={4} className="sb-input sb-shadow" placeholder="Subject" style={{ minHeight: 100 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                className="mb-3"
                name="content"
                rules={[
                  {
                    required: true,
                    email: 'Content оруулна уу!',
                  },
                ]}
              >
                <Input.TextArea rows={4} className="sb-input sb-shadow" placeholder="Content" style={{ minHeight: 200 }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
export default EmailTemplate
